import React, { useState } from 'react';

function SubscribeForm() {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const validateEmail = (email) => {
      return String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([^<>()[\]\\.,;:\s@"]+\.)+[^<>()[\]\\.,;:\s@"]{2,})$/i
          );
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        if (validateEmail(email)) {
          // Here you would typically send the email to your backend or an email service provider
          console.log("Email submitted: ", email);
          setMessage('Thank you for subscribing!');
          // Reset email field after submission
          setEmail('');
        } else {
          setMessage('Please enter a valid email address.');
        }
      };

      return (
        <form onSubmit={handleSubmit} className="mt-12 max-md:mt-10 max-md:max-w-full">
        <div className="flex gap-5 flex-wrap">
          <div className="flex flex-col grow shrink-0 justify-center py-0.5 text-base leading-7 rounded-md border border-solid basis-0 bg-stone-900 border-stone-900 text-stone-600 w-fit">
            <input
              className="z-10 justify-center items-start px-4 py-6 -mt-1 bg-white rounded-md border border-solid border-stone-900 max-md:pr-5"
              type="email"
              placeholder="Your email address..."
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <button type="submit" className="flex flex-col justify-center py-0.5 text-lg font-semibold leading-8 text-center whitespace-nowrap rounded-md border border-solid bg-stone-900 border-stone-900 text-stone-900">
            <h4 className="z-10 justify-center px-4 py-6 -mt-1 bg-sunset-300 rounded-md border border-solid border-stone-900 max-md:px-5">
              Subscribe
            </h4>
          </button>
        </div>
        {message && <p className="font-semibold text-stone-900 mt-2">{message}</p>}
      </form>
      );
    }

export default SubscribeForm;