import React from 'react';
import cruiseShipImage from '../assets/cruise-ship-cartoon.png';
import SubscribeForm from './SubscribeForm'

function ComingSoonSection() {
  return (
    <div className="flex flex-col mt-12 mb-6 max-w-full w-[566px] max-md:mt-10">
      <h1 className="self-center mt-14 text-5xl font-bold tracking-tighter leading-10 text-center text-stone-900 max-md:mt-10">
        Coming Soon
      </h1>
      <div className="mt-6 text-lg leading-8 text-center text-stone-600 max-md:max-w-full font-body">
        Your new favorite way of booking cruises to your favorite destinations is coming...
      </div>
      <SubscribeForm />
    </div>
  );
}

function App() {
  return (
    <div className="bg-royal-blue-50 min-h-screen">
      <div className="flex flex-col justify-center items-center px-16 py-20">
        <img src={cruiseShipImage} alt="Cruise Ship" className="object-contain w-45 h-auto" />
        <ComingSoonSection />
      </div>
    </div>
  );
}

export default App;
